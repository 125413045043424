import React from "react";
import {useNavigate} from "react-router-dom";

function Header(){
    
    const navigate = useNavigate();

    function handleClick(){
        navigate("/");
    }

    return(
        <div className="footer"> 

                        <div id="/about" className="misc" onClick={handleClick}>About</div>

                        <div className="misc">© NetScore 2024</div>

                        <div id="/contact-us" className="misc" onClick={handleClick}>Contact Us</div>

        </div>
    )
}

export default Header;